const zlib = require("zlib");
export function convertJsonToQRcode(json, outputFile) {
  try {
    const jsonString = JSON.stringify(json);
    const compressedData = zlib.deflateSync(Buffer.from(jsonString, "utf-8"));
    const qrData = compressedData.toString("base64");
    return qrData;
  } catch (error) {
    console.error("Error generating QR code:", error);
  }
}
