import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";

import {
  withTheme,
  withStyles,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import {
  ProgressOrError,
  withModulesManager,
  journalize,
  withHistory,
  formatDateFromISO,
  formatMessage,
} from "@openimis/fe-core";
import { fetchAllForms } from "../actions";

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
});

class AllForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedForm: null,
      anchorEl: null,
      openConfigModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchAllForms();
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRowClick = (form) => {
    this.setState((prevState) => ({
      selectedForm: prevState.selectedForm === form ? null : form, // Deselect if already selected
    }));
  };

  handleAddConfigClick = () => {
    this.setState({ openConfigModal: true });
    this.handleMenuClose();
  };

  handleCloseConfigModal = () => {
    this.setState({ openConfigModal: false });
  };

  render() {
    const { classes, allForms } = this.props;
    const { anchorEl, selectedForm, openConfigModal } = this.state;

    return (
      <>
        <Paper className={classes.paper}>
          <div
            className={classes.title}
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" }}
          >
            <Typography>Forms</Typography>
            {selectedForm && (
              <IconButton onClick={this.handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
            )}

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleMenuClose}>
              <MenuItem onClick={this.handleAddConfigClick}>Add configurations</MenuItem>
            </Menu>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Form Name</TableCell>
                <TableCell>Form Type</TableCell>
                <TableCell>Date Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allForms?.map((form, idx) => (
                <TableRow
                  key={form.formName}
                  hover
                  onClick={() => this.handleRowClick(form)}
                  selected={selectedForm === form}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedForm === form ? "#f5f5f5" : "inherit",
                  }}
                >
                  <TableCell>{form.formName}</TableCell>
                  <TableCell>{formatMessage(this.props.intl, "accredation", `form.type.${form.formType}`)}</TableCell>
                  <TableCell>
                    {formatDateFromISO(this.props.modulesManager, this.props.intl, form.createdDate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <Dialog open={openConfigModal} onClose={this.handleCloseConfigModal}>
          <DialogTitle>Add Configurations</DialogTitle>
          <DialogContent>
            <Typography>Configurations for: {selectedForm?.formName}</Typography>
            {/* Add additional configuration fields here */}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfigModal} color="primary">
              Close
            </Button>
            <Button onClick={this.handleCloseConfigModal} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation,
  fetchingAllForms: state.accredation.fetchingAllForms,
  fetchedAllForms: state.accredation.fetchedAllForms,
  allForms: state.accredation.allForms,
  errorAllForms: state.accredation.errorAllForms,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, fetchAllForms }, dispatch);
};

export default withModulesManager(
  injectIntl(withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(AllForms))))),
);
