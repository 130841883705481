import React, { Fragment } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  Button,
  Box,
  Paper,
} from "@material-ui/core";
import {
  historyPush,
  withHistory,
  TextInput,
  formatMessage,
  PublishedComponent,
  FormattedMessage,
  FormPanel,
  Contributions,
} from "@openimis/fe-core";
import {
  ProgressOrError,
  Form,
  withModulesManager,
  journalize,
  formatMessageWithValues,
  formatDateFromISO,
  Helmet,
} from "@openimis/fe-core";
import StatusPicker from "../pickers/StatusPicker";
import { PROJECT_IN_REVIEW } from "../constants";
import StatePicker from "../pickers/StatePicker";

const styles = (theme) => ({
  tableTitle: theme.table.title,
  page: theme.page,
  paper: theme.paper.paper,
  item: theme.paper.item,
  title: theme.paper.title,
  fullHeight: {
    height: "100%",
  },
});

class QualityAssuranceMasterPanel extends FormPanel {
  render() {
    const {
      classes,
      updateAttribute,
      readOnly = false,
      edited,
      accUuid,
      projectUuid,
      initiateQADetail,
      handleApproveOrRejectAccreditation,
    } = this.props;

    return (
      <Fragment>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Accreditation Details</Typography>
          <div className={classes.page}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Quality Assurance Name"
                  value={edited ? edited?.projectName : ""}
                  onChange={(e) => updateAttribute("projectName", e.target.value)}
                  disabled={edited}
                />
              </Grid>
              <Grid item xs={4}>
                <StatePicker value={edited ? edited.state : null} readOnly={edited} />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={
                    !!edited ? formatDateFromISO(this.props.modulesManager, this.props.intl, edited?.createdDate) : ""
                  }
                  label="Date Started"
                  disabled={edited}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={!!edited ? formatDateFromISO(this.props.modulesManager, this.props.intl, edited?.state) : ""}
                  label="Date Started"
                  disabled={edited}
                />
              </Grid>
            </Grid>
            {edited && !edited?.isInitialized && (
              <Box display="flex" justifyContent="end" mt={2}>
                <Button variant="contained" color="primary" onClick={initiateQADetail}>
                  Initiate Accreditation
                </Button>
              </Box>
            )}
            {edited?.state === PROJECT_IN_REVIEW && (
              <Box display="flex" justifyContent="end" mt={2}>
                <Box mx={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleApproveOrRejectAccreditation("AP", "Approved Accreditation Detail")}
                  >
                    Approve
                  </Button>
                </Box>
                <Box>
                  <Button color="error" variant="outlined">
                    Reject
                  </Button>
                </Box>
              </Box>
            )}
          </div>
        </Paper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.core.mutation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ journalize }, dispatch);

export default withModulesManager(
  injectIntl(
    withTheme(
      withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(QualityAssuranceMasterPanel))),
    ),
  ),
);
