import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import {
  withTheme,
  withStyles,
  Fab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { ProgressOrError, formatMessageWithValues, Helmet, withModulesManager, journalize } from "@openimis/fe-core"; // Grouping all imports for core utilities
import ProjectsMasterPanel from "./ProjectsMasterPanel";
import AddIcon from "@material-ui/icons/Add";
import AccreditationDataView from "./AccreditationDataView";
import QRCodeGenerator from "./QRCodeGenerator";
import AccreditationSearcher from "./AccreditationSearcher";
import { initiateAccreditation, createAccreditationProject, createQAProject } from "../actions";
import { ACCREDITATION, QUALITY_ASSURRANCE } from "../constants";
const HF_FORM_CONTRIBUTION_KEY = "location.HealthFacility";

const styles = (theme) => ({
  page: theme.page,
  lockedPage: theme.page.locked,
  paper: theme.paper.paper,
  fab: theme.fab,
});

class ProjectForm extends Component {
  state = {
    selectedTab: 0,
    isDialogOpen: false,
  };

  handleChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  handleInitiateAccreditation = () => {
    const { projectUuid } = this.props;
    if (projectUuid) {
      this.props.initiateAccreditation(projectUuid, "Initiated new accreditation");
    }
    this.setState({ isDialogOpen: false });
  };

  createProject = () => {
    if (this.props.type === ACCREDITATION) {
      this.props.createAccreditationProject(this.props.edited, "Project Created");
    }

    if (this.props.type === QUALITY_ASSURRANCE) {
      this.props.createQAProject(this.props.edited?.healthFacility, "Created QA Project");
    }
  };

  render() {
    const { classes, intl, edited, updateAttribute, projectUuid, projectId, type } = this.props;
    const { selectedTab, isDialogOpen } = this.state;
    const hospital = this.props.edited[0]?.healthFacility?.name;
    return (
      <div className={clsx(false && classes.lockedPage)}>
        <Helmet
          title={formatMessageWithValues(intl, "location", "healthFacility.edit.page.title", {
            code: "accreditation",
          })}
        />
        <ProgressOrError progress={false} error={true} />
        <Fragment>
          <ProjectsMasterPanel
            edited={edited}
            updateAttribute={updateAttribute}
            projectUuid={projectUuid}
            createProject={this.createProject}
            type={type}
          />

          {projectUuid && (
            <div className={classes.fab}>
              <Fab variant="contained" color="primary" onClick={this.handleOpenDialog}>
                <AddIcon />
              </Fab>
            </div>
          )}

          {projectUuid && (
            <div style={{ margin: 10 }}>
              <AccreditationSearcher projectUuid={projectUuid} projectId={projectId} />
            </div>
          )}
        </Fragment>

        <Dialog
          open={isDialogOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="initiate-accreditation-dialog-title"
        >
          <DialogTitle id="initiate-accreditation-dialog-title">Initiate New Accreditation</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Are you sure you want to initiate a new accreditation for ${hospital}?`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              No
            </Button>
            <Button onClick={this.handleInitiateAccreditation} color="primary" autoFocus variant="contained">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: state.core?.user?.i_user?.rights || [],
  mutation: state.accredation.mutation,
  submittingMutation: state.accredation.submittingMutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { initiateAccreditation, journalize, createAccreditationProject, createQAProject },
    dispatch,
  );
};
export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(ProjectForm)))),
);
