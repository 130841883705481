import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Button, Box } from "@material-ui/core";
import { PublishedComponent } from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withModulesManager, journalize, decodeId } from "@openimis/fe-core";
import { createODKUser } from "../actions";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import OdkUsersList from "./OdkUsersList";

const styles = (theme) => ({
  page: theme.page,
  paper: theme.paper.paper,
  title: theme.paper.title,
});

function UsersPanel(props) {
  const { classes, submittingMutation, mutation, journalize, createODKUser, projectType, detailUuid, projectId } =
    props;

  const [user, setUser] = useState(null);

  const handleAssignUser = () => {
    if (user) {
      createODKUser(user.id, projectType, detailUuid, "Assign User to Detail");
    }
  };

  // Monitor submittingMutation and mutation to trigger journalize
  useEffect(() => {
    if (submittingMutation && mutation) {
      journalize(mutation);
    }
  }, [submittingMutation, mutation, journalize]);

  console.log("UUID or ID", projectId, detailUuid);

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>Create Odk User</Typography>
      <div className={classes.page}>
        <Grid container>
          <Grid item xs={6}>
            <PublishedComponent pubRef="admin.UserPicker" onChange={(v) => setUser(v)} />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="end">
          <Button variant="contained" color="primary" onClick={handleAssignUser}>
            Create Odk User
          </Button>
        </Box>
        <OdkUsersList detailUuid={detailUuid} projectId={projectId} />
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation, // Adjust if state is different
  submittingMutation: state.accredation.submittingMutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, createODKUser }, dispatch);
};

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(UsersPanel)))),
);
