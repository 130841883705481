import React, { useState, useRef } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { CameraAlt as CameraAltIcon } from "@material-ui/icons";
import { toISODate, useModulesManager, useTranslations, PublishedComponent } from "@openimis/fe-core";
import _ from "lodash";
import moment from "moment";

const styles = (theme) => ({
  bigAvatar: theme.bigAvatar,
  hiddenInput: {
    display: "none",
  },
  item: {
    ...theme.paper.item,
    paddingInline: 0,
  },
  cameraIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "50%",
    boxShadow: theme.shadows[2],
  },
  dialogActions: {
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  video: {
    width: "100%",
    height: "auto",
    marginBottom: theme.spacing(2),
  },
});

const InsureeAvatar = (props) => {
  const { photo, classes, className, withMeta = false, readOnly, onChange } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const modulesManager = useModulesManager();
  const { formatMessage } = useTranslations("insuree", modulesManager);

  const getUrl = (photo) => {
    if (photo?.photo) {
      return `data:image/png;base64,${photo.photo}`;
    }
    if (photo?.filename) {
      return `/photos/${photo.folder}/${photo.filename}`;
    }
    return null;
  };

  const onFileSelect = (event) => {
    if (!!event.target.files) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.onloadend = (loaded) => {
        onChange({
          ...photo,
          folder: null,
          filename: null,
          photo: loaded.target.result.split(",")[1], // Extract base64 part
          date: toISODate(moment().toDate()),
        });
        setDialogOpen(false); // Close dialog after successful upload
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const handleTakePhoto = async () => {
    setCameraOpen(true);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }
  };

  const handleCapture = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert canvas to base64 and save as photo
      const photoData = canvas.toDataURL("image/png").split(",")[1];
      onChange({
        ...photo,
        folder: null,
        filename: null,
        photo: photoData,
        date: toISODate(moment().toDate()),
      });

      // Stop the video stream
      const stream = video.srcObject;
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }

      setCameraOpen(false);
      setDialogOpen(false);
    }
  };

  const handleCloseCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setCameraOpen(false);
  };

  return (
    <Grid container className={className} direction="row" wrap="nowrap" spacing={1}>
      <div style={{ position: "relative" }}>
        <IconButton component="span" size="small">
          <Avatar src={getUrl(photo)} alt={formatMessage("Insuree.photoAlt")} className={classes.bigAvatar} />
        </IconButton>
        {!readOnly && (
          <IconButton className={classes.cameraIcon} onClick={handleOpenDialog}>
            <CameraAltIcon color="primary" />
          </IconButton>
        )}
      </div>
      {withMeta && (
        <Grid container direction="column" item>
          <Grid item className={classes.item}>
            <PublishedComponent
              pubRef="core.DatePicker"
              value={photo?.date}
              module="insuree"
              label="Insuree.photoDate"
              readOnly={readOnly}
              required={Boolean(photo?.thumbnail || photo?.photo)}
              onChange={(date) => onChange({ ...photo, date })}
            />
          </Grid>
          <Grid item className={classes.item}>
            <PublishedComponent
              pubRef="insuree.InsureeOfficerPicker"
              value={photo?.officerId}
              module="insuree"
              label={formatMessage("Insuree.photoOfficer")}
              readOnly={readOnly}
              required={Boolean(photo?.thumbnail || photo?.photo)}
              onChange={(v) => onChange({ ...photo, officerId: v?.id })}
            />
          </Grid>
        </Grid>
      )}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{formatMessage("Enrollee Photo")}</DialogTitle>
        <DialogContent>
          <input
            type="file"
            className={classes.hiddenInput}
            id="upload-photo"
            accept="image/*"
            onChange={onFileSelect}
          />
          {/* Two button for choose or take a photo */}
          <DialogContent>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <label htmlFor="upload-photo">
                <Button variant="contained" color="primary" component="span">
                  {formatMessage("Choose a File")}
                </Button>
              </label>
              <Button variant="contained" color="primary" onClick={handleTakePhoto}>
                {formatMessage("Take a Photo")}
              </Button>
            </div>
          </DialogContent>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCloseDialog} color="secondary">
            {formatMessage("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={cameraOpen} onClose={handleCloseCamera}>
        <DialogTitle>{formatMessage("Take a Photo")}</DialogTitle>
        <DialogContent>
          <video ref={videoRef} className={classes.video}></video>
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        </DialogContent>
        {/* Buttons to handle picture taking */}
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCapture} color="primary" variant="contained">
            {formatMessage("Capture")}
          </Button>
          <Button onClick={handleCloseCamera} color="secondary">
            {formatMessage("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default withTheme(withStyles(styles)(InsureeAvatar));
